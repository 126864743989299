@import "~bootstrap/scss/functions.scss";


$enable-rounded: false;
$primary: #004876;
$secondary: #c7c9c7;
$light: #f1f0ed;
$dark: #000e;
$body-bg: $light;
$body-color: #4d4d4d;
$input-bg: #fff;
$badge-border-radius: 0;
$alert-border-radius: 0;
$nav-link-padding-y: 0.2rem;

@import "~bootswatch/dist/lux/variables.scss";
@import "~bootstrap/scss/variables.scss";
@import "~bootstrap/scss/variables-dark.scss";

@import "~bootstrap/scss/maps.scss";
@import "~bootstrap/scss/mixins.scss";
@import "~bootstrap/scss/utilities.scss";
@import "~bootstrap/scss/root.scss";
@import "~bootstrap/scss/reboot.scss";

@import "~bootstrap/scss/type.scss";
@import "~bootstrap/scss/grid.scss";
@import "~bootstrap/scss/containers.scss";
@import "~bootstrap/scss/badge.scss";
@import "~bootstrap/scss/alert.scss";
@import "~bootstrap/scss/spinners.scss";
@import "~bootstrap/scss/buttons.scss";
@import "~bootstrap/scss/button-group.scss";
@import "~bootstrap/scss/card.scss";
@import "~bootstrap/scss/close.scss";
@import "~bootstrap/scss/forms.scss";
@import "~bootstrap/scss/forms/input-group.scss";
@import "~bootstrap/scss/modal.scss";
@import "~bootstrap/scss/nav.scss";
@import "~bootstrap/scss/navbar.scss";
@import "~bootstrap/scss/toasts.scss";
@import "~bootstrap/scss/dropdown.scss";

@import "~bootstrap/scss/helpers.scss";

@import "~bootstrap/scss/utilities/api.scss";
@import "~bootswatch/dist/lux/bootswatch.scss";

// https://github.com/JedWatson/react-select/issues/2345#issuecomment-843674624

:root {
  --bs-select-padding-y: #{$form-select-padding-y}; //
  --bs-select-padding-x: #{$form-select-padding-x}; //
  --bs-select-font-size: #{$form-select-font-size}; //
  --bs-select-indicator-padding: #{$form-select-indicator-padding};
  --bs-select-font-weight: #{$form-select-font-weight}; //
  --bs-select-line-height: #{$form-select-line-height}; //
  --bs-select-color: #{$form-select-color}; //
  --bs-select-bg: #{$form-select-bg}; //
  --bs-select-disabled-color: #{$form-select-disabled-color};
  --bs-select-disabled-bg: #{$form-select-disabled-bg};
  --bs-select-disabled-border-color: #{$form-select-disabled-border-color};
  --bs-select-bg-position: #{$form-select-bg-position};
  --bs-select-bg-size: #{$form-select-bg-size};
  --bs-select-indicator: #{escape-svg($form-select-indicator)};
  --bs-select-feedback-icon-padding-end: #{$form-select-feedback-icon-padding-end};
  --bs-select-feedback-icon-position: #{$form-select-feedback-icon-position};
  --bs-select-feedback-icon-size: #{$form-select-feedback-icon-size};
  --bs-select-border-width: #{$form-select-border-width}; //
  --bs-select-border-color: #{$form-select-border-color}; //
  --bs-select-focus-border-color: #{$form-select-focus-border-color}; //
  --bs-select-padding-y-sm: #{$form-select-padding-y-sm};
  --bs-select-padding-x-sm: #{$form-select-padding-x-sm};
  --bs-select-font-size-sm: #{$form-select-font-size-sm};
  --bs-select-padding-y-lg: #{$form-select-padding-y-lg};
  --bs-select-padding-x-lg: #{$form-select-padding-x-lg};
  --bs-select-font-size-lg: #{$form-select-font-size-lg};

  @if $enable-rounded {
    --bs-select-border-radius: #{$form-select-border-radius}; //
  }

  @else {
    --bs-select-border-radius: 0; //
  }
}

body,
html,
#root {
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  overflow: hidden;
  font-family: "ITC Franklin Gothic", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

nav {
  font-family: "ITC Franklin Gothic", "Franklin Gothic", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.alert h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  color: var(--bs-alert-color);
}

.navbar,
.navbar-nav {
  --bs-navbar-height: 40px;
  --bs-navbar-padding-y: 0;
  height: 40px;
}

.nav>.nav-item>.nav {
  padding-left: 1rem;
  margin-top: 0.25rem;
}

.navbar-nav .nav-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.toc>.nav-item {
  font-weight: 500;
}

.toc>.nav-item>.nav {
  font-weight: 400;
  display: block;
}

.toc-container {
  position: sticky;
  top: 1rem;
  right: 0;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
  height: 95vh;
  margin-top: 1rem;
}

.toc {
  padding-bottom: 5rem;

  .nav {
    font-size: 0.875rem;
    display: block;
  }
}

.col-2 .toc .nav-item {
  width: 16.66667vw;
}

.toc a {
  color: inherit;
}

.form-inner {
  padding-bottom: 50vh !important;
}

.form-group[class*="col-"] {
  padding: 0;
}

.form-group [class*="col-"] {
  padding-left: 0;
  padding-right: 1px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 5fr));
  width: 100%;
}

.fill {
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  width: 100%;
}

.half-fill {
  height: 50%;
}

.fill-vhw {
  height: calc(100vh - 10rem);
  border-width: 0;
  max-width: 100%;
  width: 100%;
}

.fill-vw {
  border-width: 0;
  max-width: 100%;
  width: 100vw;
}

.loading {
  position: absolute;
  top: 30%;
  left: 50%;
}

.full-frame {
  height: calc(100% - 56px - 0.5rem);
  width: 100vw;
  border-width: 0;
}

.center {
  position: absolute;
  top: 40%;
}

.card[class*="col-"] {
  padding: 0;
}

.row {
  margin: 0;
}

.alert {
  margin-bottom: 0;
  padding: 0.2rem 1.25rem;
}

.hidden {
  visibility: hidden;
}

.loading-spinner {
  width: 3rem;
  height: 3rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group:last-child {
  margin-bottom: 0;
}

.form-pane-closed {
  flex-grow: 1 !important;
}

.pane-closed {
  display: none;
}

.top-level-card {
  background: #fff;
  --bs-card-border-width: 0.5rem;
}

.nested-card {
  --bs-card-border-width: 0.5px;
}

.top-level-card:hover {
  background: #f6f6f6;
}

.card-container>.card-body {
  padding: 0.5rem 0.5rem 0 0;
}

.card-body {
  padding: 0.5rem 0.5rem 0.5rem 1.25rem;
}

.card-body .card-body {
  padding-right: 0;
}

.top-level-card>.card-body>.row {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.card-body>.row {
  margin-right: -5px;
  margin-left: -5px;
}

.card-body>.row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.card-body>.row>[class*="col-"]:first-child {
  padding-left: 0;
}

.card.no-padding>.card-body {
  padding: 0;
}

.top-level-card>.card-body .card {
  --bs-card-border-width: 0;
  margin-top: 0;
  margin-bottom: 0 !important;
  outline: 0.5px solid var(--bs-border-color-translucent);
  background: #fafafa;
}

.top-level-card>.card-body .card:hover {
  background: #f1f1f1;
}

@keyframes cardHighlight {
  0% {
    background-color: $white;
  }

  100% {
    background-color: mix($primary, $white, 30%);
  }
}

.card.active,
.card.active *>.card-body {
  animation-name: cardHighlight;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 2;
  animation-direction: alternate;
}

.dice-btn {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  height: 28px;
  filter: invert(50%);
}

.btn>.dice-btn {
  padding: 0 0.25rem 0 0;
}

.btn-outline-dark .dice-btn {
  filter: invert(100%);
  height: 30px;
}

.btn-outline-dark:hover>.dice-btn,
.dice-btn:hover {
  filter: brightness(100%);
}

.bs-tooltip-bottom {
  margin-top: 1rem;
  padding: 0rem 0.5rem 0rem 0.5rem;
  background-color: #24272a;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.75);
  font-size: 0.9rem;
  font-weight: 400;
}

.tooltip {
  background: $dark;
  color: $light;
  position: absolute;
  z-index: 9999;
  padding: 0.25rem;
}

.search-view {
  margin-top: 15vh;
}

.search-btn {
  --bs-btn-color: var(--bs-btn-hover-color);
}

nav .search-btn {
  padding: 0 0.5rem 0.25rem;
  border: none;
}

/** change if statusbar brand font size changes */
form {
  margin-bottom: 2.8rem;
}

.form-select {
  background-position: right 0.75rem center
}

.form-control {
  padding: 0.75rem;
}

.statusbar>.navbar-brand {
  font-size: 1.5rem;
}

.statusbar {
  padding: 0.25rem 1rem;
}

.navbar {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.scrollable-container {
  height: 95vh;
  overflow-y: auto;
}

.form-search {
  margin-bottom: 0;
}

.form-label {
  font-weight: 400;
  font-size: 1.05rem;
}

.notes {
  margin-bottom: 5vh;
}

.form-input-padding {
  width: 1.25rem;
}

.form-input-label {
  width: 16rem;
  align-self: center;
  word-wrap: break-word;
  padding-right: 0.2rem;
}

.input-group>.form-input-tag {
  padding: 0 !important;
  border: 0;
}

.form-input-tag>div {
  height: 100%;
}

.form-input-tag>div>div {
  min-height: 100%;
}

.input-group>.form-input-contribution {
  flex: 0.1 1 !important;
  min-width: 5em;
}

.form-control,
.input-group-append,
.input-group-sm>.form-control,
.card,
.input-group-sm>.input-group-append>.input-group-text {
  border-radius: 0 !important;
}

.input-group-fh {
  height: 100%;
}

.submit-button {
  margin-left: 1rem;
  --bs-btn-padding-y: 0.2rem;
}

.clear-button {
  margin-left: 1rem;
  --bs-btn-padding-y: 0.2rem;
}

.card-group>.card {
  margin-bottom: 0;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #80bdff;
  background: none;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.nav-pad {
  margin-top: 1rem;
}

button:disabled {
  cursor: not-allowed;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0.25rem;
}

.navbar-brand>img {
  height: 15px;
  filter: brightness(1.6);
}

.stats-container {
  overflow-y: auto;
  height: 95vh;
}

.stats-container>.container-fluid {
  height: 85vh;
}

.stats-container>.text-center>h4 {
  margin-top: 0.5rem;
}

.stats-container>.nav {
  margin-bottom: 0.5rem;
}

.dash-chart {
  width: 100%;
  height: 100%;
}

.tab-nav {
  width: 100%;
}

.tab-nav-item {
  color: inherit;
}

.tab-content {
  height: 90%;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.tab {
  height: 100%;
}

.bar_graph {
  min-height: 30rem;
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 45rem;
  width: 100%;
}

.line_graph {
  min-height: 30rem;
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 45rem;
  width: 100%;
}

.removed_table {
  min-height: 25rem;
  max-height: 100%;
  height: 100%;
  min-width: 15rem;
  max-width: 100%;
  width: 20%;
}

.lookup-chart {
  width: 50%;
  height: 20rem;
  margin-left: auto;
  margin-right: auto;
  min-width: 15rem;
  /* max-height: 40vh; */
}

.float-top {
  position: absolute;
  left: 10%;
  right: 10%;
  z-index: 100;
  max-width: 50vw;
}

.modebar-group {
  padding-bottom: 4px !important;
}

.toc .nav-item {
  margin-right: 0;
}

.toc .nav-link {
  --bs-nav-link-padding-y: 0.2rem;
}

.toc .nav-link:hover {
  text-decoration: underline;
}

.toc .nav-link.active {
  --bs-nav-pills-link-active-bg: var(--bs-border-color-translucent);
  color: $navbar-light-active-color;
}

.toc.nav>.nav-item>.nav {
  margin-top: 0;
}

.chart-row-container .row>* {
  padding-left: 0;
}

nav .form-select {
  padding: 0 0.5rem;
}

nav .form-select,
nav .form-control {
  height: 100%;
}

.overlay-icon {
  z-index: 100;
  max-width: 50vw;
  padding: 2px;
}

.toast-container {
  z-index: 999;
}

.category-and-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left {
  flex: 1;
  text-align: left;
  max-width: 40em;
}

.right {
  flex: '0 1 20%';
  text-align: right;
  padding-right: 13px;
  padding-left: 13px;
  width: 5em;
}